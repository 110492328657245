<template>
  <div class="thank-you-page">
    <CategoryTitle :category="category" />
    <v-container class="mt-5">
      <v-card flat class="elevation-0">
        <v-card-actions class="justify-center">
          <v-btn
            v-if="showLogin && !isAuthenticated"
            @click="handleLogin"
            color="primary"
            depressed
          >
            Accedi ora
          </v-btn>
          <v-btn v-else to="/" color="primary" depressed>
            Torna alla homepage
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
// import Breadcrumb from "@/components/navigation/Breadcrumb.vue";

import category from "~/mixins/category";
import login from "~/mixins/login";

import { mapGetters } from "vuex";

export default {
  name: "ResetPasswordSent",
  mixins: [category, login],
  props: { showLogin: { type: Boolean, default: false } },
  components: {
    CategoryTitle
    //  Breadcrumb
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    })
    // headerBanner() {
    //   if (
    //     this.category &&
    //     this.category.metaData &&
    //     this.category.metaData.category_info &&
    //     this.category.metaData.category_info &&
    //     this.category.metaData.category_info.HEADER_IMAGE
    //   ) {
    //     return this.category.metaData.category_info.HEADER_IMAGE;
    //   }
    //   return null;
    // }
    // categoryName() {
    //   return category.name;
    // },
    // vBreadcrumb() {
    //   let breadCrumbs = [];
    //   breadCrumbs.push({
    //     to: { name: "Home" },
    //     text: "Homepage",
    //     exact: true
    //   });
    //   breadCrumbs.push({
    //     to: {
    //       name: "EbsnCategory"
    //     },
    //     text: this.categoryName,
    //     exact: true
    //   });
    //   return breadCrumbs;
    // }
  },
  methods: {
    async handleLogin() {
      await this.doLogin("EbsnCategory");
      if (await this.needAddress()) {
        await this.needTimeslot();
        this.$router.push({
          name: "Home",
          path: "/"
        });
      }
    }
  },
  created() {
    //this.isLoggedIn("EbsnCategory");
  }
};
</script>

<style></style>
